<template>
    <div class="template-page news">
        <basic-page :pagedata="this.pageData" :bannerimg="this.newsImage">
            <div class="deadline" v-if="newsData.attributes && newsData.attributes.field_is_opportunity_news && newsData.attributes.field_opportunity_deadline">
                <h3><span>DEADLINE: </span> {{ deadline }}</h3>
            </div>

            <div v-if="newsData.attributes && newsData.attributes.field_news_content" v-html="newsData.attributes.field_news_content.processed"></div>
            <div v-else>No content</div>

            <br>

            <div class="video-container" v-if="newsData && newsData.attributes && newsData.attributes.field_youtube_video">
                <iframe allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
                frameborder="0"
                class="video" 
                :src="'https://www.youtube.com/embed/' + this.newsData.attributes.field_youtube_video">
                </iframe>
            </div>

            <h4 v-if="this.newsData.field_image_gallery && this.newsData.field_image_gallery.length">Image gallery</h4>
            <div class="gallery" 
                v-if="this.newsData.field_image_gallery && this.newsData.field_image_gallery.length">
                <img v-for="(image, index) in this.newsData.field_image_gallery" 
                    :key="'img_gall_'+index" 
                    :src="fileSrc(image)" 
                    :alt="newsData.relationships.field_image_gallery.data[index].meta.alt"
                >
            </div>

            <br>
            <br>
            
            <h3 v-if="this.newsData.field_news_attachment && this.newsData.field_news_attachment.length">Attachment/s</h3>
            <div v-if="newsData.field_news_attachment && newsData.field_news_attachment.length">
                <div v-for="(attachment, index) in newsData.field_news_attachment" :key="index">
                    <a v-if="pageData.relationships.field_news_attachment.data[index].meta.description"
                        class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ pageData.relationships.field_news_attachment.data[index].meta.description }}</a>
                    <a v-else class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                        {{ attachment.attributes.filename }}
                    </a>
                </div>
            </div>

            <br>
            <div class="back-button">
                <router-link to="/news" class="cta-link">
                    <span>Back to News</span>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from '../BasicPage.vue'
import { fetchNodes, fetchSingleNode } from '../../libs/drupalClient';
import { convertMonth } from '../../libs/utils'
export default {
    name: 'news',
    components: {
        BasicPage
    },
    data: () => {
        return {
            pageData: {
                relationships: {}
            },
            introimg: '',
            introimgbannerID: 'bd4230a3-bd52-424f-ba37-aef12581efc5',
            newsData: {}
        }
    },
    computed: {
        newsNID() {
            return this.$route.params.nid
        },
        newsImage() {
            return process.env.VUE_APP_ENDPOINT + (this.newsData.field_news_image ? this.newsData.field_news_image.attributes.uri.url : this.introimg)
        },
        deadline() {
            if(this.newsData.attributes.field_is_opportunity_news && this.newsData.attributes.field_opportunity_deadline) {
                var d = new Date(Date.parse(this.newsData.attributes.field_opportunity_deadline))
                return d.getDate() + ' ' + convertMonth(d.getMonth()) + ' ' + d.getFullYear()
            } else
                return ''
        }
    },
    methods: {
        fileSrc(att) {
            return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
        },
        fetchNews() {
            if(this.newsNID) {
                fetchSingleNode('page', this.introimgbannerID, { 
                    include: ['field_banner_top'] }
                ).then(res => {
                    if(res[0].field_banner_top) this.introimg = res[0].field_banner_top.attributes.uri.url
                })

                fetchNodes('news', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.newsNID
                    }],
                    include: ['field_news_image', 'field_news_attachment', 'field_image_gallery']
                }).then(res => {
                    //console.log(res)
                    this.newsData = res[0]
                    //this.pageData.title = this.newsData.attributes.title

                    var d = new Date(Date.parse(this.newsData.attributes.field_news_data))

                    this.pageData = {
                        title: this.newsData.attributes.title,
                        body: [
                            {
                                summary: d.getDate() + ' ' + convertMonth(d.getMonth()) + ' ' + d.getFullYear()
                            }
                        ],
                        relationships: this.newsData.relationships
                    }

                    //this.pageData.body[0].summary = d.getDate() + ' ' + convertMonth(d.getMonth()) + ' ' + d.getFullYear()
                    //this.pageData.body[0].summary = reWriteDate(this.newsData.attributes.field_news_data)
                })
            }   
        },
    },
    mounted() {
        this.fetchNews()
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

.deadline h3 {
    padding: .3em .7em;
    margin: 0 0 1em 0;
    background: $mainColor;
    color: $secondaryColor;
    text-transform: unset;
    width: fit-content;

    span { font-weight: 700; }
}

.gallery {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.gallery img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

h4 {
    font-size: 1.25rem;
    color: $mainColor;
    margin: 1em 0 .5em 0;
}

@media (min-width: 1024px) {
    .gallery {
        grid-template-columns: 1fr 1fr;
    }
}

</style>